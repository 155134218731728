<template>
  <v-app-bar
    app
    color="primary"
    dark
  >
    <v-toolbar-title>{{ $route.meta.title }}</v-toolbar-title>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'DefaultLayoutHeader',
  };
</script>

<style scoped>

</style>
